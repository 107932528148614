export const precachingImages = `bool _isInit = true;

@override
void didChangeDependencies() {
  if (_isInit) {
    for (BackgroundLayerType layerType in BackgroundLayers.types) {
      precacheImage(
        Image.asset('assets/images/background/\${layerType.name}.png').image,
        context,
      );
    }

    for (int size in Puzzle.supportedPuzzleSizes) {
      precacheImage(
        Image.asset('assets/images/puzzle-solved/solved-\${size}x$size.png').image,
        context,
      );
    }
  }
  _isInit = false;
  super.didChangeDependencies();
}`

export const backgroundLayersList = `static List<BackgroundLayerType> types = [
  BackgroundLayerType.topBgPlanet,
  BackgroundLayerType.topRightPlanet,
  BackgroundLayerType.topLeftPlanet,
  BackgroundLayerType.bottomLeftPlanet,
  BackgroundLayerType.bottomRightPlanet,
];`

export const skslCommands = `flutter build apk --bundle-sksl-path flutter_01.sksl.json

flutter build appbundle --bundle-sksl-path flutter_01.sksl.json

flutter build ios --bundle-sksl-path flutter_01.sksl.json
`
