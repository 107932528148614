import React from "react"
import TutorialsLayout from "../../components/tutorials-layout"
import Seo from "../../components/seo"
import PrismCode from "../../components/prims/PrismCode"
import {
  backgroundLayersList,
  precachingImages,
  skslCommands,
} from "../../data/code/performance-tutorial-code"
import imagesFolder from "../../images/tutorials/images-folder.png"
import ShareArticle from "../../components/share-article/ShareArticle"
import GetTheAppSection from "../../components/get-the-app-section/GetTheAppSection"

function PerformancePage() {
  return (
    <>
      <Seo
        title="Performance"
        titleSuffix="Dashtronaut Tutorials"
        image="https://dashtronaut.app/images/performance-tutorial-preview.png"
      />
      <h1>Performance Improvements</h1>
      <h4>
        Precaching images as well as Reducing shader compilation jank on mobile
        by caching warmed up SkSL
      </h4>
      <p className="mt-5">
        Improving the performance of your application is always important. And
        in the case of this slide puzzle game app, there were lots of animations
        and graphics, so it was vital that some work and effort be directed into
        making sure the performance of the app was at its best.
      </p>
      <p>
        Aside from using const in every possible place, having smaller build
        methods, having minimum rebuilds to update the UI, 2 major performance
        optimizations were done:
      </p>
      <ol>
        <li>Precaching images</li>
        <li>
          Reducing shader compilation jank by caching warmed up SKSL (Skia
          Shader Language)
        </li>
      </ol>
      <h2>1. Precaching Images</h2>
      <p>
        This is especially important for the planets you see in the background.
        On the first app run, they animate in, so it’s important that their
        images are pre-loaded so that they don’t suddenly appear halfway through
        the animation.
      </p>
      <p>
        This is done with a simple “<strong>precacheImage</strong>” function
        built-in in flutter. All you have to do is call this method, I’m calling
        it in the <strong>didChangeDependencies</strong> of my App widget, and
        give it the value of the <strong>ImageProvider</strong> of your asset
        image like the code below.
      </p>
      <p>
        <a
          href="https://github.com/Roaa94/flutter-puzzle-hack/blob/main/lib/app.dart"
          target="_blank"
          rel="noreferrer"
        >
          You can see the following code in the app.dart file in the source
          code.
        </a>
      </p>
      <PrismCode code={precachingImages} />
      <p>
        I’ve avoided calling this function in the <strong>initState</strong>{" "}
        method because the function takes a <strong>BuildContext</strong> and we
        want to make sure the context of our app is ready to be used, so we call
        it in the <strong>didChangeDependencies</strong> method that is called
        right after initState. I used the <strong>_isInit</strong> variable to
        make sure the precaching is done only once.
      </p>
      <p>
        In the code above, I’m utilizing Dart’s enum features by using the
        “name” property of my <strong>BackgroundLayerType</strong> enums that I
        have in a list in the{" "}
        <a
          href="https://github.com/Roaa94/flutter-puzzle-hack/blob/main/lib/presentation/background/utils/background_layers.dart"
          rel="noreferrer"
          target="_blank"
        >
          background_layers.dart
        </a>{" "}
        file
      </p>
      <PrismCode code={backgroundLayersList} />
      <p>
        I’m also precaching the images that are displayed when each of the
        puzzle sizes is won. And I’m using my supportedPuzzleSizes array that I
        defined in the puzzle.dart file for this.
      </p>
      <PrismCode code="static List<int> supportedPuzzleSizes = [3, 4, 5, 6];" />
      <p>This is the content of the images folder:</p>
      <img
        style={{ width: "300px" }}
        src={imagesFolder}
        alt="Images Folder - Dashtronaut Flutter Slide Puzzle Game"
      />
      <h2>2. SKSL Shader Warm-Up</h2>
      <p>
        Have you ever noticed animations being “janky” when you run your app the
        first time, then they run smoothly? This is exactly what warming up SKSL
        shaders helps you avoid.
      </p>
      <p>
        Flutter uses Skia as a graphics engine to render its visuals. Shaders
        are the code that runs on a GPU to display those visuals. For example, a
        Flutter route push/pop, app drawer slide in/out, bottom sheets, or any
        other UI rendering/animations. These shaders, when run on a device, need
        to be compiled on that device first and this compilation takes more time
        than the frame time required by Flutter’s 60 frames per second
        rendering. And then they run smoothly after they are compiled. This
        longer time is what causes the jank that you see only on first runs.
      </p>
      <p>
        Flutter gives you a way to “warm up” those shaders by storing compiled
        shaders in a json file in the <strong>Skia Shader Language</strong>{" "}
        format. You can then bundle the app with this file and build it.
      </p>
      <p>
        All you need to do is run your app in profile mode with the flag “
        <strong>--cache-sksl</strong>”, play with the app and trigger as many
        animations as possible when the app is running, and then hit{" "}
        <strong>M</strong>. Flutter then writes a json file that you can bundle
        in your next app build.
      </p>
      <p>The command for running the app:</p>
      <PrismCode code="flutter run --profile --cache-sksl --purge-persistent-cache" />
      <p>
        (Add the <strong>--purge-persistent-cache</strong> flag only if you ran
        the app without caching SKSL before)
      </p>
      <p>The commands for building the app with the generated json file:</p>
      <PrismCode code={skslCommands} />
      <p>
        Please note that for better performance, you should do the above for
        each platform separately (Android/iOS) and bundle the corresponding json
        file to ensure platform-specific shaders are warmed up.
      </p>
      <p className="mb-2">Resources:</p>
      <ol>
        <li>
          <a
            href="https://docs.flutter.dev/perf/rendering/shader"
            target="_blank"
            rel="noreferrer"
          >
            Official documentation for SKSL warm-up
          </a>
        </li>
        <li>
          <a
            href="https://docs.flutter.dev/resources/architectural-overview"
            target="_blank"
            rel="noreferrer"
          >
            Flutter Architecture
          </a>
        </li>
      </ol>
      <ShareArticle
        title="Flutter App Performance Improvements By Precaching Images and Warming Up SKSL Shaders | Dashtronaut Puzzle Game Tutorials"
        url="https://dashtronaut.app/tutorials/performance"
      />
      <GetTheAppSection />
    </>
  )
}

PerformancePage.Layout = TutorialsLayout

export default PerformancePage
